body {
  font-family: Arial, sans-serif;
  margin: 20px;
}
input, textarea, button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 400px;
}
h1, h2 {
  margin-bottom: 20px;
}
button {
  cursor: pointer;
}
